import React from "react";
import { Link } from "gatsby";
import Footer from "../layout/Footer";
import Logo from "../components/Logo";
import {
  FaArrowLeft
} from "react-icons/fa";


const CookiePolicy = ({ data }) => (
  <main className="bg-primary">
    <header className="bg-primary-darker">
        <div className="container mx-auto py-2">
            <div className="text-4xl text-primary py-2 uppercase font-bold">
                <img
                    src={"renovant-logo-inverted.svg"}
                    alt="Renovant logo"
                    className="w-64"
                />
            </div>
        </div>
    </header>
    <div className="container mx-auto py-10">
    <Link to="/" className="text-black"> <FaArrowLeft className="mb-10 text-3xl"/></Link>
        <div className="flex flex-col items-center">
            <div className="w-3/5">
                <div
                    className="mb-10 text-3xl uppercase text-center"
                    dangerouslySetInnerHTML={{
                        __html: data.prismicCookiePolicy.data.title.html
                    }}
                />
                <div
                    dangerouslySetInnerHTML={{
                        __html: data.prismicCookiePolicy.data.body.html
                    }}
                />
            </div>
        </div>
    </div>

    <Footer />
  </main>
);

export default CookiePolicy;

export const pageQuery = graphql`
  query CookieQuery {
    prismicCookiePolicy {
      data {
        body {
          html
        }
        title {
          html
        }
      }
    }
  }
`;
